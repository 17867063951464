import { defineStore } from 'pinia'

export const useWorkSpaceStore = defineStore('workspace', {

    state: () => {
        return {
            currentWorkSpace: null,
            workspaces: [],
        }
    },
    persist: true,
    getters: {
        currentWorkSpaceID: (state) => (state.currentWorkSpace ? state.currentWorkSpace.id : state.workspaces[0].id),
    },
    actions: {

        setCurrentWorkSpace(workspace) {
            this.currentWorkSpace = workspace
        },

        setWorkSpaces(workspaces) {

            this.workspaces = workspaces

            if (this.currentWorkSpace == null && workspaces.length > 0) {
                this.setCurrentWorkSpace(workspaces[0])
            }
        },

        resetState() {
            this.currentWorkSpace = null
            this.workspaces = []
        },

        addNewWorkspaceToList(items) {

            if (!items) return

            if (!Array.isArray(items)) items = [items]

            items.forEach((item) => {
                this.workspaces.push(item)
            })
        },
        deleteWorkspace(itemId) {

            //remove workspace form workspaces list
            this.workspaces = this.workspaces.filter((item) => item.id !== itemId)


            //set current workspace to the first workspace
            if (this.currentWorkSpaceID === itemId) {
                this.setCurrentWorkSpace(this.workspaces.length > 0 ? this.workspaces[0] : null)
            }
        }
    },
})
